import { getWordFail, getWordStart, getWordSuccess } from "../reducer";
import { getWord } from "../reducer/wordsReducer";
import { GET_ALL, GET_WORD } from "./commom";
import { axiosInstance } from "./config";
// import { dictionary } from "./db";

export const getWordAPI = async (dispatch) => {
  dispatch(getWordStart());
  try {
    const res = await axiosInstance.get(GET_ALL);
    if (res.status === 200) {
      dispatch(getWordSuccess(res.data));
    }
  } catch (error) {
    dispatch(getWordFail("Error server! Please return after"));
    console.error("Error server");
  }
};
