import { useEffect, useState } from "react";
import "./App.css";

import { getWordAPI } from "./redux/api";
import { useDispatch, useSelector } from "react-redux";
import { images } from "./assets/images";
import { ToastContainer, toast } from "react-toastify";
// import { dictionary } from "./redux/api/db";

function App() {
  const dispatch = useDispatch();
  const [resultFilter, setResultFilter] = useState([]);
  const [valueSearch, setValueSearch] = useState({ value: "", search: "thai" });
  const [toggleSuggest, setToggleSuggest] = useState(false);
  const [resultSearch, setResultSearch] = useState([]);
  const dictionary = useSelector((state) => state.words);

  // Get data first time
  useEffect(() => {
    getWordAPI(dispatch);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // render isLoading
  useEffect(() => {
    if (dictionary.isLoading) {
      const resolveAfter3Sec = new Promise((resolve) =>
        setTimeout(resolve, 3000)
      );
      toast.promise(resolveAfter3Sec, {
        pending: "Các bạn chờ tí nhé!!!",
        success: " Bây giờ cùng tra từ nào!!!👌",
        error: "Lỗi rồi!!! Hẹn gặp bạn sau!",
      });
    }
  }, [dictionary.isLoading]);

  // Render when data change
  useEffect(() => {
    setResultSearch(() => {
      const newResult = [];
      if (dictionary.data.length > 0) {
        for (let index = 0; index < 10; index++) {
          const element = dictionary.data[index];
          newResult.push(element);
        }
      }
      return newResult;
    });
  }, [dictionary.data]);

  // handle change value search
  const handleChangeSearch = (event) => {
    setToggleSuggest(true);
    setValueSearch({ ...valueSearch, value: event.target.value.trim() });
  };

  // Handle submit search
  const handleSearch = () => {
    setValueSearch({ ...valueSearch, value: "" });
    setToggleSuggest(false);
    setResultSearch(() => {
      const newResult = dictionary.data.filter((word) => {
        if (valueSearch.search === "viet") {
          return word?.mean
            .toLowerCase()
            .includes(valueSearch.value.toLowerCase());
        } else {
          return word?.word
            .toLowerCase()
            .includes(valueSearch.value.toLowerCase());
        }
      });
      newResult.sort((pre, next) => {
        if (valueSearch.search === "thai") {
          return pre.word.length - next.word.length;
        } else {
          return pre.mean.length - next.mean.length;
        }
      });
      const data = newResult.slice(0, 30);
      return data;
    });
  };

  useEffect(() => {
    const handleClickFilter = (element) => {
      if (element.target.className !== "li-content") {
        setToggleSuggest(false);
      }
    };
    window.addEventListener("click", (element) => {
      handleClickFilter(element);
    });
    return () => {
      window.removeEventListener("click", (element) => {
        handleClickFilter(element);
      });
    };
  }, [toggleSuggest]);
  useEffect(() => {
    const newResult = dictionary.data.filter((word) => {
      if (!word.mean || !word.word) {
        return;
      }
      if (valueSearch.search === "viet") {
        return word?.mean
          .toLowerCase()
          .includes(valueSearch?.value.toLowerCase());
      } else {
        return word?.word
          .toLowerCase()
          .includes(valueSearch?.value.toLowerCase());
      }
    });
    newResult.sort((pre, next) => {
      if (valueSearch.search === "thai") {
        return pre.word.length - next.word.length;
      } else {
        return pre.mean.length - next.mean.length;
      }
    });
    setResultFilter(newResult.slice(0, 15));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueSearch]);

  return (
    <main className=" min-h-screen p-12 h-screen main-dictionary">
      <section className="main-content p-12">
        <h1 className="text-center uppercase py-4 font-bold text-xl">
          <img src={images.logo} width={80} />
          Từ điển Thái - Việt
        </h1>
        <div id="form-search">
          <div className="flex">
            <select
              defaultValue={"thai"}
              onChange={(e) =>
                setValueSearch({ ...valueSearch, search: e.target.value })
              }
              id="dropdown-button"
              data-dropdown-toggle="dropdown"
              className="flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-l-lg hover:bg-gray-200 4 focus:outline-none dark:bg-gray-700 dark:hover:bg-gray-600  dark:text-white dark:border-gray-600"
            >
              <option value="thai">Thái - Việt</option>
              <option value="viet">Việt - Thái</option>
            </select>

            <div className="relative w-full input-search">
              <input
                onClick={() => setToggleSuggest(true)}
                type="search"
                id="search-dropdown"
                className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-r-lg border-l-gray-50 border-l-2 border border-gray-300  dark:bg-gray-700 dark:border-l-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white "
                placeholder="Nhập từ cần tìm kiếm"
                onChange={handleChangeSearch}
                value={valueSearch.value}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
              />

              <button
                onClick={handleSearch}
                type="submit"
                className="absolute top-0 right-0 p-2.5 text-sm font-medium text-white bg-blue-700 rounded-r-lg border border-blue-700 hover:bg-blue-800  focus:outline-none  dark:bg-blue-600 dark:hover:bg-blue-700 "
              >
                <svg
                  aria-hidden="true"
                  className="w-5 h-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
                <span className="sr-only">Search</span>
              </button>

              {toggleSuggest && (
                <ul class="bg-white border border-gray-100 w-full mt-2 suggest-search">
                  {resultFilter &&
                    resultFilter.map((word, index) => (
                      <li
                        className="pl-8 pr-2 py-1 border-b-2 border-gray-100 relative cursor-pointer hover:bg-yellow-50 hover:text-gray-900 li-content"
                        onClick={() => {
                          setValueSearch({
                            ...valueSearch,
                            value:
                              valueSearch.search === "thai"
                                ? word.word
                                : word.mean,
                          });

                          setToggleSuggest(false);
                        }}
                        key={index}
                      >
                        {valueSearch.search === "thai" ? word.word : word.mean}
                      </li>
                    ))}
                </ul>
              )}
            </div>
          </div>
        </div>
        {valueSearch.value ? (
          <h3>Kết quả tìm kiếm</h3>
        ) : (
          <h3>Top từ thông dụng</h3>
        )}
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg change-scroll">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  STT
                </th>
                <th scope="col" className="px-6 py-3">
                  Từ
                </th>
                <th scope="col" className="px-6 py-3">
                  Loại
                </th>
                <th scope="col" className="px-6 py-3">
                  Nghĩa
                </th>
              </tr>
            </thead>
            <tbody>
              {resultSearch &&
                resultSearch.map((item, index) => (
                  <tr
                    className={
                      index % 2
                        ? " bg-green border-b dark:bg-gray-900 dark:border-gray-700 "
                        : "bg-white-custom border-b dark:bg-gray-900 dark:border-gray-700"
                    }
                    key={index}
                  >
                    <th
                      scope="row"
                      className="px-6 py-4  whitespace-nowrap dark:text-white"
                    >
                      {index + 1}
                    </th>
                    <td className="px-6 py-4"> {item.word}</td>
                    <td className="px-6 py-4"> {item.type}</td>
                    <td className="px-6 py-4"> {item.mean}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </section>
      <ToastContainer autoClose={3000} />
    </main>
  );
}

export default App;
