import { createSlice } from "@reduxjs/toolkit";

const getWordSlice = createSlice({
  name: "getWords",
  initialState: {
    isLoading: false,
    errorMessage: "",
    data: [],
  },
  reducers: {
    getWordStart: (state) => {
      state.isLoading = true;
    },
    getWordSuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    getWordFail: (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },
  },
});

export const { getWordStart, getWordSuccess, getWordFail } =
  getWordSlice.actions;
export default getWordSlice.reducer;
