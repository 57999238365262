import { createSlice } from '@reduxjs/toolkit';

const getMeanSlice = createSlice({
    name: 'getWords',
    initialState: [],
    reducers: {
        getMean: (state, action) => {
            state.getWord.data = action.payload;
        },
    },
});

export const { getMean } = getMeanSlice.actions;
export default getMeanSlice.reducer;
